@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap);
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
code,
em,
img,
small,
strong,
sub,
sup,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
footer,
header,
nav,
section,
time,
audio,
video {
  font-size: 100%;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  background-color: transparent;
}

html {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  overflow-y: scroll;
  box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
small,
hgroup,
summary {
  display: block;
}

img,
embed,
iframe,
object,
audio,
video {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
}

iframe {
  border: 0;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input {
  line-height: normal;
  border-radius: 0;
}

input,
select {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  font-family: inherit;
  margin: 0;
  text-transform: none;
  border: 0;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

strong,
b {
  font-weight: bold;
}

abbr,
acronym {
  cursor: help;
  text-decoration: none;
  border-bottom: 0.1em dotted;
}

a:active,
a:hover {
  outline-width: 0;
}

label,
input[type="file"],
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}

[disabled] {
  cursor: not-allowed;
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

h1 {
  font-family: 'Lato';
  font-size: 76px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0.12em;
  text-align: left;
}

h2 {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.12em;
  text-align: left;
}

