@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

h1 {
  font-family: 'Lato';
  font-size: 76px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0.12em;
  text-align: left;
}

h2 {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.12em;
  text-align: left;
}
